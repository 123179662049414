import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppStateService } from '../app-state.service';
import { forkJoin, Observable, switchMap } from 'rxjs';
import {
  Lab,
  Link,
  LocaleService,
  ResultsDataResourcesService,
  WorkQueuesService,
  WorkspacesService,
} from '@lims-common-ux/lux';
import { Inject, Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { ResultsDataResource } from '@lims-common-ux/lux/lib/data-resources/results-data-resource.interface';
import { TranslateService } from '@ngx-translate/core';
import { PageResource } from '../interfaces/pageResource.interface';
import { PAGE_RESOURCE } from '../application-init.service';

@Injectable({ providedIn: 'root' })
export class LabResolver {
  private readonly resultLink: Link;

  constructor(
    private resultResourceService: ResultsDataResourcesService,
    private workspacesService: WorkspacesService,
    private translate: TranslateService,
    private appStateService: AppStateService,
    private workQueueService: WorkQueuesService,
    @Inject(PAGE_RESOURCE) resource: PageResource,
    private localeService: LocaleService
  ) {
    this.resultLink = resource._links.results;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ lab: Lab; locale: string }> {
    const labId = route.paramMap.get('lab');
    const lab = this.appStateService.labs.find((listLab) => listLab.id === labId);
    this.localeService.setUrlLocale(route.queryParams.locale);

    this.appStateService.lab = lab;

    return this.resultResourceService.getResultsDataResource(this.resultLink, lab).pipe(
      tap((resource: ResultsDataResource) => {
        this.appStateService.configureSharedLinks(resource);
      }),
      switchMap((resource: ResultsDataResource) =>
        forkJoin([
          this.workspacesService.loadWorkspaces(resource._links.workspaces),
          this.workQueueService.getWorkQueues(),
        ])
      ),
      switchMap(([workspacesResponse, workQueuesResponse]) => {
        this.appStateService.workspaces = workspacesResponse;
        this.appStateService.workQueues = workQueuesResponse;
        return this.translate.use(this.localeService.selectedLocale);
      }),
      map(() => ({ lab, locale: this.localeService.selectedLocale })) // lab and local update should both trigger route.data observable
    );
  }
}
