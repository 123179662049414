import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Comment, CommentsComponent, Lab } from '@lims-common-ux/lux';
import { Assay } from '../../interfaces/assay.interface';
import { AssayService } from '../assay/assay.service';
import { BulkActionsService } from './bulk-actions.service';

@Component({
  selector: 'app-bulk-actions',
  templateUrl: './bulk-actions.component.html',
  styleUrl: './bulk-actions.component.scss',
})
export class BulkActionsComponent implements OnInit {
  @ViewChildren('removeBulkActionItem')
  bulkSelectionDeleteButtons = new QueryList<ElementRef>();

  @ViewChild('resultBulkComment', { static: false })
  resultBulkComment!: CommentsComponent;

  @Input()
  lab: Lab;

  @Output()
  requestRepeat = new EventEmitter<void>();

  @Output()
  markAsNoResult = new EventEmitter<void>();

  @Output()
  selectCurrentAssay = new EventEmitter<Assay>();

  @Output()
  escapeResultEntry: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  selectedAssaysUpdated: EventEmitter<Assay[]> = new EventEmitter<Assay[]>();

  bulkActionItems$ = this.bulkActionService.bulkActionItems$;
  bulkActionRepeatDisabled$ = this.bulkActionService.bulkActionRepeatDisabled$;
  bulkActionNoResultDisabled$ = this.bulkActionService.bulkActionNoResultDisabled$;
  bulkActionCanModifyAssay$ = this.bulkActionService.bulkActionCanModifyAssay$;

  assayCommentsDataSource: string;

  constructor(
    private bulkActionService: BulkActionsService,
    private assayService: AssayService
  ) {}

  ngOnInit() {
    this.assayCommentsDataSource = this.bulkActionService.commentsDataSource;
  }

  deselectBulkActionItem(assay: Assay, event?) {
    if (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    const currentAssayPos = this.bulkActionService.bulkActionItems.findIndex(
      (selectedAssay) => selectedAssay.testCode === assay.testCode
    );

    // Focus first assay in the list
    let assayIndexToFocus = 0;
    let bulkActionItemsLength = this.bulkActionService.bulkActionItems.length;

    if (currentAssayPos < bulkActionItemsLength - 1) {
      // Focus next assay in the list
      assayIndexToFocus = currentAssayPos + 1;
    } else if (bulkActionItemsLength > 1 && currentAssayPos === bulkActionItemsLength - 1) {
      // Focus previous assay in the list
      assayIndexToFocus = currentAssayPos - 1;
    }

    const nextFocusAssay = this.bulkSelectionDeleteButtons.toArray()[assayIndexToFocus];
    const nextFocusAssayTestCode = nextFocusAssay.nativeElement?.getAttribute('data.assay-test-code');

    this.bulkActionService.bulkActionDeselect(assay);

    if (nextFocusAssayTestCode) {
      this.selectCurrentAssay.emit(nextFocusAssayTestCode);
      requestAnimationFrame(() => {
        nextFocusAssay?.nativeElement.focus();
      });
    }
  }

  bulkActionRepeat(event) {
    this.requestRepeat.emit();
  }

  bulkActionNoResult(event) {
    this.markAsNoResult.emit();
  }

  deselectAllBulkActionItems() {
    const lastAssayTestCode = this.bulkSelectionDeleteButtons.last?.nativeElement?.getAttribute('data.assay-test-code');

    this.bulkActionService.deselectAllBulkActionItems();

    this.selectCurrentAssay.emit(lastAssayTestCode);
  }

  onAddCommentBulk(comment: Comment) {
    if (!comment || !this.bulkActionService.bulkSelectedAssaysCanModify) {
      return;
    }

    const updatedAssays = this.bulkActionService.bulkActionItems.filter((assay) =>
      this.assayService.setComment(comment, assay)
    );
    if (updatedAssays.length) {
      this.selectedAssaysUpdated.emit(updatedAssays);
    }

    // Close search results and return focus to search input
    this.resultBulkComment.resetComments();

    // Return focus to selected assay card
    this.escapeResultEntry.emit();
  }

  focusResultBulkComment() {
    setTimeout(() => {
      this.resultBulkComment.focusSearchInput();
    }, 0);
  }
}
