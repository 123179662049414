import { Comment, DefinedTextValue, Link, ShortCodeMapping } from '@lims-common-ux/lux';

interface Flag {
  message: string;
  code: string;
}

export interface ResultDefinition {
  valueType: string;
  types?: DefinedTextValue[] | string[];
  intervals?: ResultInterval[];
  valueCombinations?: any;
}

export interface Assay {
  allResults: Result[];
  operationalId: any;
  testCode: string;
  status: AssayStatus;
  presentationStatus: string;
  presentationValue?: any;
  canModify: boolean;
  canRepeat: boolean;
  canNoResult: boolean;
  repeatRequested: boolean;
  hasChanges: boolean;
  version: number;
  resultRequired: ResultRequired;
  attributes: any[];
  name: string;
  clinicalReference: string;
  expectedPerformingLab: string;
  resultDefinition: ResultDefinition;
  shortCodes?: ShortCodeMapping;
  result: Result;
  comments: Comment[];
  // This is purely a UI construct. Used to keep updates separate from current values
  updatedResult: {
    previousResult?: string;
    // since result values can take various forms we are using 'any' here
    value: any;
    // noResult is an actual type in `value` but because we use the value for things like form controls we can't share
    // that for this
    noResult: boolean;
  };
  _links: {
    self: Link;
  };
}

export interface Result {
  value: any;
  presentationValue?: any;
  relevanceIndicator?: string;
  interval?: ResultInterval;
  enteredBy: string;
  timestamp: string;
  resultId: string;
  flags?: Flag[];
  instrument?: {
    name: string;
  };
  transformedValue?: string;
}

export enum ResultRequired {
  ESSENTIAL = 'ESSENTIAL',
  NON_ESSENTIAL = 'NON_ESSENTIAL',
}

export interface ResultInterval {
  low: number;
  high?: number;
  customerFacingText: string;
}

export enum AssayStatus {
  CANCELED = 'CANCELED',
  OPENED = 'OPENED',
  REPEAT_REQUESTED = 'REPEAT_REQUESTED',
  RESULT_RECEIVED = 'RESULT_RECEIVED',
  TECHNICALLY_ACCEPTED = 'TECHNICALLY_ACCEPTED',
  TECHNICIAN_REVIEW = 'TECHNICIAN_REVIEW',
  RELEASED = 'RELEASED',
}

export function isDefinedTextValue(value: string | DefinedTextValue): value is DefinedTextValue {
  return (value as DefinedTextValue).code !== undefined;
}
